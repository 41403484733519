<template>
	<div
		class="flex-column justify-center bottom"
	>
		<div
			class="bg-login-content box"
		>
			<div
				class=" text-center"
			>
				<div
					class="bg-primary size-px-16 color-white font-weight-bold pa-10 "
				><img :src="require(`@/assets/images${$skin.img}logo_bottom.png`)" class="logo"/></div>
			</div>
			<div class="justify-space-between bg-base">
				<h6 class="pa-10 ">내 정보</h6>
			</div>
			<ul
				class="box-mypage-list shadow ul-under-line size-px-14 "
			>
				<li
					class="pa-10 justify-space-between items-center gap-20"
				>
					<span class="hide-m">신규 비밀번호</span>
					<span class="flex-1">
						<input
							v-model="item.member_password"
							type="password" placeholder="신규 비밀번호를 입력하세요."
							maxlength="50"
							class="input-box width-100"
						/>
					</span>
				</li>
				<li
					class="pa-10 justify-space-between items-center gap-20"
				>
					<span class="hide-m">비밀번호 확인</span>
					<span class="flex-1">
						<input
							v-model="item.password_confirm"
							type="password" placeholder="비밀번호를 한번 더 입력하세요."
							maxlength="50"
							class="input-box width-100"
						/>
					</span>
				</li>
			</ul>
		</div>
		<div
			class="mt-30 text-center"
		>
			<button
				class="btn-fill btn-round"
				@click="save"
			>비밀번호 변경</button>
		</div>
	</div>
</template>

<script>

export default{
	name: 'PasswordC'
	, components: {}
	, props: ['user']
	, data: function(){
		return {
			program: {
				name: '비밀번호 변경'
				, bottom: true
				, title: true
				, bland: false
				, wrap: 'loginNew'
				, is_container: true
				, top_line: true
			}
			,item: {
				member_id: this.user.member_id
				,old_password: ''
				,member_password: ''
				,password_confirm: ''
				, type: 'find'
			}
			,view: {
				result: false
			}
			,isSave: true
		}
	}
	,methods: {
		save: async function(){

			if(!this.item.member_password){
				this.$bus.$emit('notify', {type: 'error', message: '신규 비밀번호를 입력해주세요.'})
				return
			}

			if(!this.item.password_confirm){
				this.$bus.$emit('notify', {type: 'error', message: '신규 비밀번호를 한번 더 입력해주세요'})
				return
			}

			if (this.item.member_password != this.item.password_confirm) {
				this.$bus.$emit('notify', {type: 'error', message: '신규 비밀번호가 맞지 않습니다. 정확하게 입력 해주세요.'})
				return
			}

			try{
				const result = await this.$request.init({
					method: 'post'
					, url: 'member/postMemberPassword'
					, data: this.item
				})

				if(result.success){
					this.$bus.$emit('notify', { type: 'success', message: '정상적으로 처리되었습니다' })
					this.item.member_password = ''
					this.item.old_password = ''
					this.item.password_confirm = ''

					this.toLogin()
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message })
				}
			}catch(E){
				console.log(E)
				this.$bus.$emit('notify', { type: 'error', message: E })
			}
		}

		,toLogin: function(){
			this.$bus.$emit('notify', { type: 'success', message: '정상적으로 처리되었습니다' })
			this.$bus.$emit('to', {name: 'Login'})
		}
	}
	,created: function(){
		this.$bus.$emit('onLoad', this.program)
	}
}

</script>

<style>
.bg-login { background-color: #eee; padding: 10px;}
.bg-login-content { background-color: white; border-radius: 5px; min-width: 320px; max-width: 480px; margin: 0 auto}

.auto-login { vertical-align: middle; font-size: 14px; }

.login-menu { }
.login-menu a { font-size: 14px; font-weight: 400}

.toggle-password { position: relative; }
.toggle-password .toggle { position: absolute; right: 10px; top: 20px; color: #bbb}
.toggle-password .toggle.on { color: #0f6ecd}

.error-underline { background: none; border-bottom: 1px solid red !important;}

</style>