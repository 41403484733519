<template>
	<div
		class="flex-column justify-center "
	>
		<div
			v-if="!is_pw"
			class="bg-login-content box bottom"
		>
			<div
				class=" text-center"
			>
				<div
					class="bg-primary size-px-16 color-white font-weight-bold pa-10 "
				><img :src="require(`@/assets/images${$skin.img}logo_bottom.png`)" class="logo"/></div>
			</div>
			<div class="pa-10">
				<h6>아이디</h6>
				<input
					v-model="item.member_id"
					type="text" placeholder="아이디를 입력하세요"
					class="input-underline"
					maxlength="20"
				/>
			</div>
			<div class="pa-10">
				<h6>이름</h6>
				<input
					v-model="item.member_name"
					type="text" placeholder="이름을 입력하세요"
					class="input-underline"
					maxlength="20"
				/>
			</div>
			<div class="pa-10">
				<h6>연락처</h6>
				<input
					v-model="item.member_phone"
					type="number" placeholder="연락처를 입력하세요"
					class="input-underline"
					:rules="[$rules.max(item, 'member_phone', 15)]"
				/>
			</div>
			<div class="pa-10 text-center">
				<button
					@click="onConfirm"
					class="btn-fill btn-round"
					:disabled="disabled"
				>비밀번호 찾기</button>
			</div>
		</div>

		<PasswordC
			v-if="is_pw"
			:Axios='Axios'
			:user='item'
			:rules='rules'
			:type='type'

			@setNotify="setNotify"
		></PasswordC>

		<div
			v-if="is_on_confirm"
			class="position-fixed-full"
		>

			<div
				style="position: absolute; width: 100%; height: 100%; background-color: black; opacity: 0.5"
			></div>
			<div
				style="position: relative; height: 100%; background: none; display: flex; flex-direction: column; justify-content: center; z-index: 9999"
				class="color-white text-center size-px-24"
			>

				<iframe
					v-show="true"
					:src="nice_url"
					class="full-height full-width"
				></iframe>
			</div>
		</div>
	</div>
</template>

<script>

import PasswordC from "./PasswordC";
export default{
	name: 'FindPw'
	,props: ['Axios', 'rules']
	,components: {PasswordC }
	,data: function(){
		return {
			program: {
				name: '비밀번호 찾기'
				, bottom: true
				, title: true
				, bland: false
				, wrap: 'loginNew'
				, is_container: true
				, top_line: true
			}
			,type: 'find'
			,member_id: ''
			,item: {
				member_id: ''
				, member_name : ''
				, member_phone : ''
			}
			,is_pw: false
			, is_on_confirm: false
			, nice_url: ''
		}
	}
	,computed: {
		search_id: function(){
			let id = ''
			if(this.member_id){
				let length = this.member_id.length
				let start = Math.ceil(length / 3)
				let end = Math.floor(length / 3)
				console.log('length : ' + length + ' start: ' + start + ' end: ' + end)
				id = this.member_id.slice(0, start)
					+ this.member_id.slice(start, start + end).replace(/(?<=.{0})./gi, "*")
					+ this.member_id.slice(start + end, length)

			}
			return id
		}
		, disabled: function(){
			let t = true
			if(this.item.member_id && this.item.member_name && this.item.member_phone){
				t = false
			}
			return t
		}
	}
	,methods: {
		confirm: function(){

		}
		,findId: async function(){
			try {
				const result = await this.$request.init({
					method: 'post'
					, url: 'member/getMemberPw'
					, data: this.item
				})

				if (result.success) {
					this.is_pw = true
				} else {
					this.$bus.$emit('notify', {type: 'error', message: result.message})
				}
			} catch (E) {
				console.log(E)
				this.$bus.$emit('notify', {type: 'error', message: E})
			}
		}
		,toLogin: function(){
			try{

				const NUA = navigator.userAgent.toLowerCase()

				if(NUA.indexOf('android') > -1){
					window.Android.finishFindId()
				}else if(NUA.indexOf('iphone') > -1 || NUA.indexOf('ipad') > -1 || NUA.indexOf('ipod') > -1){
					window.webkit.messageHandlers.callback.postMessage('finishFindId')
				}else{
					document.location.href = '/Login'
				}
			}catch(e){
				document.location.href = '/Login'
			}
		}
		,setNotify: function( {type, message}){
			this.$bus.$emit('notify', { type: type, message: message })
		}

		, onConfirm: function(){
			if(this.item.member_name && this.item.member_phone){
				window.open(this.nice_url, 'nice', 'width=320px')
			}
		}
		, niceResult: function(e){
			// console.log('niceResult', e.data)
			try {
				let domains = process.env.VUE_APP_DOMAIN.split('|')
				let t = false
				for(let i = 0; i < domains.length; i++){
					if(e.origin.indexOf(domains[i]) > -1){
						t = true
					}
				}
				if(t){
					let data = e?.data
					console.log(data)
					if(data && typeof data == 'string'){
						data = JSON.parse(data)
						if(data.type == 'find'){
							if(data.result){
								if(this.item.member_id == data.data.member_id && this.item.member_name == data.data.name && this.item.member_phone == data.data.phone){
									this.findId()
								}else{
									throw "인증정보가 맞지않습니다"
								}
							}else{
								throw data.message
							}
						}
					}
				}
			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e})
			}
		}

	}
	, destroyed() {
		window.removeEventListener('message', this.niceResult)
	}
	,created: function(){
		this.$bus.$emit('onLoad', this.program)

		let baseUrl = this.$baseUrl

		this.nice_url = baseUrl + '/nice/request?type=find'

		window.removeEventListener('message', this.niceResult)
		setTimeout( () => {
			window.addEventListener('message', this.niceResult)
		}, 100)
	}
}
</script>

<style>
.bg-login { background-color: #eee; padding: 10px;}
.bg-login-content { background-color: white; border-radius: 5px; min-width: 320px; max-width: 480px; margin: 0 auto}

.auto-login { vertical-align: middle; font-size: 14px; }

.login-menu { }
.login-menu a { font-size: 14px; font-weight: 400}

.toggle-password { position: relative; }
.toggle-password .toggle { position: absolute; right: 10px; top: 20px; color: #bbb}
.toggle-password .toggle.on { color: #0f6ecd}

.error-underline { background: none; border-bottom: 1px solid red !important;}

</style>